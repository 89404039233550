import React, { Component } from 'react';
import './Home.css'
import { ThetaDropConnect } from '@thetalabs/theta-drop-connect';
import ComicViewer from './ComicViewer';
import './ThetaDrop.css';


//new info
const infoID = 'dapp_xvdzecv186y9h8hujdmf18ri4ar';  
const redirectURLNew = 'https://comics.cykoverse.com/'
const uriNew = "https://comics.cykoverse.com" + '/thetadrop-auth-finished.html';   



class ThetaDropConnectComponent extends Component {

  constructor(props) {
    super(props)
    this.thetaDrop = new ThetaDropConnect();

    this.state = {
      userName: null,
      userData: null,
      isOwner: false,
      comicEdition: props.edition,
      typeID: null
    }

  }





  componentDidMount() {
    if (this.state.comicEdition === "1") {
      this.setState({ typeID: "type_d0iiichid93qaahwmqj26f544cs" })
      const newLink = redirectURLNew + "1";
      this.setState({newLink});
      this.finishConnectViaRedirect();
    } else if (this.state.comicEdition === "2") {
      this.setState({ typeID: "type_n22ym67135me7vr0pis0j7f3g1k" })
      const newLink = redirectURLNew + "2";
      this.setState({newLink});
      this.finishConnectViaRedirect();
    } else if (this.state.comicEdition === "3") {
      this.setState({ typeID: "type_0mnngyinc83uhs9exruy68qz9xw" })
      const newLink = redirectURLNew + "3";
      this.setState({newLink});
      this.finishConnectViaRedirect();
    } else if (this.state.comicEdition === "4") {
      this.setState({ typeID: "type_s9mcmezfn1qctc55jn32j6iuu2i"})
      const newLink = redirectURLNew + "4";
      this.setState({newLink});
      this.finishConnectViaRedirect();
    } else {
      alert("Error with Comic ID")
    }
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });


  }

  finishConnectViaRedirect = async () => {
    const result = await this.thetaDrop.finishConnectViaRedirect();

    if (result) {
      const { snsId, oauth2Token } = result;

      this.setState({
        tpsId: snsId,
        authToken: oauth2Token
      });

      this.refreshUser();
      this.refreshOwnershipChecks();
    }
  }

  refreshOwnershipChecks = async () => {
    const filters = {
      content_id: this.state.typeID
    };
    const userInfo = await this.thetaDrop.fetchUserNFTs(filters);
    let nfts = userInfo.nfts;

    const isOwner = await this.thetaDrop.checkUserIsOwner(filters);
    console.log(isOwner)
    this.setState({
      isOwner: isOwner
    });

  }


  refreshUser = async () => {
    const userData = await this.thetaDrop.fetchUser();
    window.sessionStorage.setItem('cykoverseUserData', JSON.stringify(userData));
    this.setState({
      userData: userData
    });
    window.sessionStorage.setItem("cykoUserName", userData.username);
  }

  connectToThetaDrop = async () => {
    const { snsId, oauth2Token } = await this.thetaDrop.connectViaPopup(infoID, this.state.newLink);

    this.setState({
      tpsId: snsId,
      authToken: oauth2Token
    });

    this.refreshUser();
    this.refreshOwnershipChecks();
  };

  connectToThetaDropViaRedirect = async (e) => {
    e.preventDefault();
    console.log(this.state.newLink)
    this.thetaDrop.connectViaRedirect(infoID, this.state.newLink);
  };




  render() {
    return (
      <div className='home-theta-drop'>
        {
          (this.state.userData === null) &&
          <div>
            <button onClick={(e) => { this.connectToThetaDropViaRedirect(e) }}>Connect To Theta Drop</button>
          </div>
        }
        {
          (this.state.userData && this.state.isOwner === true) &&
          <div>
            <ComicViewer edition={this.state.comicEdition} />
          </div>
        }
        {
          (this.state.userData && this.state.isOwner !== true) &&
          <div>
            <h2>User does not own the required NFT.</h2>
          </div>
        }
      </div>

    )
  }
}

export default ThetaDropConnectComponent;
