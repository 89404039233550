import React, { Component } from 'react';
import './Home.css'
import MMConnect from './MMConnect';
import ThetaDropConnectComponent from './ThetaDropConnectComponent'


class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            connector: 0, //1 is thetadrop connect, 2 is MM
            comicEdition: 0, //0 is none, 1-4
        }

    }


    async componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const comicEdition = queryParams.get("comic");
        this.setState({comicEdition});
        const tpsId = queryParams.get("sns_id");
        console.log(comicEdition)
        if(tpsId !== null){
            this.setState({connector : 1})
        }
        const pathparams = new URLSearchParams(window.location);
        const info = Object.fromEntries(pathparams.entries());
        if(info.pathname === "/1"){
            this.setState({comicEdition : "1"})
        } else if(info.pathname === "/2"){
            this.setState({comicEdition : "2"})
        } else if(info.pathname === "/3"){
            this.setState({comicEdition : "3"})
        } else if(info.pathname === "/4"){
            this.setState({comicEdition : "4"})
        }
    }

    connectThroughThetaDrop(e){
        e.preventDefault();
        this.setState({connector : 1})
    }

    connectThroughMM(e){
        e.preventDefault();
        this.setState({connector : 2})
    }


    render() {
        return (
            <div className='home'>
                {
                    this.state.connector === 0 &&
                    <div className='home-selection-container'>
                        <img src='./cykoverse-wlogo.png' alt='main-logo'/>
                        <h2>Chose How to Connect Your NFT</h2>
                        <button onClick={(e)=>this.connectThroughThetaDrop(e)}>ThetaDrop</button>
                        <button onClick={(e)=>this.connectThroughMM(e)}>MetaMask</button>
                        </div>
                }
                {
                    this.state.connector === 1 &&
                    <ThetaDropConnectComponent edition={this.state.comicEdition}/>
                }
                {
                    this.state.connector === 2 &&
                    <MMConnect edition={this.state.comicEdition}/>
                }

            </div>

        )
    }
}

export default Home;
