import React, { Component } from 'react';
import './Home.css'
import './ComicViewer.css'


class ComicViewer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            comic: props.edition,
        }

    }


    componentDidMount() {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
    }




    render() {
        return (
            <div className='home'>
                {
                    this.state.comic === "1" &&
                    <div className='comic-parent-container'>
                        <img src='./comics/Issue1/00.png' alt="Panel 0" />
                        <img src='./comics/Issue1/01.png' alt="Panel 1" />
                        <img src='./comics/Issue1/02.png' alt="Panel 2" />
                        <img src='./comics/Issue1/03.png' alt="Panel 3" />
                        <img src='./comics/Issue1/04.png' alt="Panel 4" />
                        <img src='./comics/Issue1/05.png' alt="Panel 5" />
                        <img src='./comics/Issue1/06.png' alt="Panel 6" />
                        <img src='./comics/Issue1/07.png' alt="Panel 7" />
                        <img src='./comics/Issue1/08.png' alt="Panel 8" />
                        <img src='./comics/Issue1/09.png' alt="Panel 9" />
                        <img src='./comics/Issue1/10.png' alt="Panel 10" />
                        <img src='./comics/Issue1/11.png' alt="Panel 11" />
                        <img src='./comics/Issue1/12.png' alt="Panel 12" />
                        <img src='./comics/Issue1/13.png' alt="Panel 13" />
                        <img src='./comics/Issue1/14.png' alt="Panel 14" />
                        <img src='./comics/Issue1/15.png' alt="Panel 15" />
                        <img src='./comics/Issue1/16.png' alt="Panel 16" />
                        <img src='./comics/Issue1/17.png' alt="Panel 17" />
                        <img src='./comics/Issue1/18.png' alt="Panel 18" />
                        <img src='./comics/Issue1/19.png' alt="Panel 19" />
                        <img src='./comics/Issue1/20.png' alt="Panel 20" />
                        <img src='./comics/Issue1/21.png' alt="Panel 21" />
                        <img src='./comics/Issue1/22.png' alt="Panel 22" />
                        <img src='./comics/Issue1/23.png' alt="Panel 23" />
                        <img src='./comics/Issue1/24.png' alt="Panel 24" />
                        <img src='./comics/Issue1/25.png' alt="Panel 25" />
                        <img src='./comics/Issue1/26.png' alt="Panel 26" />
                        <img src='./comics/Issue1/27.png' alt="Panel 27" />
                    </div>
                }
                {
                    this.state.comic === "2" &&
                    <div className='comic-parent-container'>
                        <img src='./comics/Issue2/00.png' alt="Panel 0" />
                        <img src='./comics/Issue2/01.png' alt="Panel 1" />
                        <img src='./comics/Issue2/02.png' alt="Panel 2" />
                        <img src='./comics/Issue2/03.png' alt="Panel 3" />
                        <img src='./comics/Issue2/04.png' alt="Panel 4" />
                        <img src='./comics/Issue2/05.png' alt="Panel 5" />
                        <img src='./comics/Issue2/06.png' alt="Panel 6" />
                        <img src='./comics/Issue2/07.png' alt="Panel 7" />
                        <img src='./comics/Issue2/08.png' alt="Panel 8" />
                        <img src='./comics/Issue2/09.png' alt="Panel 9" />
                        <img src='./comics/Issue2/10.png' alt="Panel 10" />
                        <img src='./comics/Issue2/11.png' alt="Panel 11" />
                        <img src='./comics/Issue2/12.png' alt="Panel 12" />
                        <img src='./comics/Issue2/13.png' alt="Panel 13" />
                        <img src='./comics/Issue2/14.png' alt="Panel 14" />
                        <img src='./comics/Issue2/15.png' alt="Panel 15" />
                        <img src='./comics/Issue2/16.png' alt="Panel 16" />
                        <img src='./comics/Issue2/17.png' alt="Panel 17" />
                        <img src='./comics/Issue2/18.png' alt="Panel 18" />
                    </div>
                }
                {
                    this.state.comic === "3" &&
                    <div className='comic-parent-container'>
                        <img src='./comics/Issue3/00.png' alt="Panel 0" />
                        <img src='./comics/Issue3/01.png' alt="Panel 1" />
                        <img src='./comics/Issue3/02.png' alt="Panel 2" />
                        <img src='./comics/Issue3/03.png' alt="Panel 3" />
                        <img src='./comics/Issue3/04.png' alt="Panel 4" />
                        <img src='./comics/Issue3/05.png' alt="Panel 5" />
                        <img src='./comics/Issue3/06.png' alt="Panel 6" />
                        <img src='./comics/Issue3/07.png' alt="Panel 7" />
                        <img src='./comics/Issue3/08.png' alt="Panel 8" />
                        <img src='./comics/Issue3/09.png' alt="Panel 9" />
                        <img src='./comics/Issue3/10.png' alt="Panel 10" />
                        <img src='./comics/Issue3/11.png' alt="Panel 11" />
                        <img src='./comics/Issue3/12.png' alt="Panel 12" />
                        <img src='./comics/Issue3/13.png' alt="Panel 13" />
                        <img src='./comics/Issue3/14.png' alt="Panel 14" />
                        <img src='./comics/Issue3/15.png' alt="Panel 15" />
                        <img src='./comics/Issue3/16.png' alt="Panel 16" />
                        <img src='./comics/Issue3/17.png' alt="Panel 17" />
                        <img src='./comics/Issue3/18.png' alt="Panel 18" />
                    </div>
                }
                 {
                    this.state.comic === "4" &&
                    <div className='comic-parent-container'>
                        <img src='./comics/Issue4/00.png' alt="Panel 0" />
                        <img src='./comics/Issue4/01.png' alt="Panel 1" />
                        <img src='./comics/Issue4/02.png' alt="Panel 2" />
                        <img src='./comics/Issue4/03.png' alt="Panel 3" />
                        <img src='./comics/Issue4/04.png' alt="Panel 4" />
                        <img src='./comics/Issue4/05.png' alt="Panel 5" />
                        <img src='./comics/Issue4/06.png' alt="Panel 6" />
                        <img src='./comics/Issue4/07.png' alt="Panel 7" />
                        <img src='./comics/Issue4/08.png' alt="Panel 8" />
                        <img src='./comics/Issue4/09.png' alt="Panel 9" />
                        <img src='./comics/Issue4/10.png' alt="Panel 10" />
                        <img src='./comics/Issue4/11.png' alt="Panel 11" />
                        <img src='./comics/Issue4/12.png' alt="Panel 12" />
                        <img src='./comics/Issue4/13.png' alt="Panel 13" />
                        <img src='./comics/Issue4/14.png' alt="Panel 14" />
                        <img src='./comics/Issue4/15.png' alt="Panel 15" />
                        <img src='./comics/Issue4/16.png' alt="Panel 16" />
                        <img src='./comics/Issue4/17.png' alt="Panel 17" />
                        <img src='./comics/Issue4/18.png' alt="Panel 18" />
                        <img src='./comics/Issue4/19.png' alt="Panel 19" />
                        <img src='./comics/Issue4/20.png' alt="Panel 20" />
                        <img src='./comics/Issue4/21.png' alt="Panel 21" />
                        <img src='./comics/Issue4/22.png' alt="Panel 22" />
                        <img src='./comics/Issue4/23.png' alt="Panel 23" />
                        <img src='./comics/Issue4/24.png' alt="Panel 24" />
                        <img src='./comics/Issue4/25.png' alt="Panel 25" />
                        <img src='./comics/Issue4/26.png' alt="Panel 26" />
                        <img src='./comics/Issue4/27.png' alt="Panel 27" />
                    </div>
                }
            </div>

        )
    }
}

export default ComicViewer;
