import React, { Component } from 'react';
import './Home.css'
import Web3 from 'web3';
import TNT721Contract from './truffle_abis/TNT721.json';
import ComicViewer from './ComicViewer';


class MMConnect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            account: '0x0',
            loading: true,
            statusCode: 0, //0 ready to play, //1 no metamask, //2 is not connected //3 not on the right network  //4 don't own meepie
            contractAddress: "0x0",
            comicEdition: props.edition,
        }

    }


    async componentDidMount() {
        if (this.state.comicEdition === "1") {
            this.setState({ contractAddress: "0x89e2df79e973fccf03e53b86d4a849f3059f40b6" })
            this.initialCheck();
        } else if (this.state.comicEdition === "2") {
            this.setState({ contractAddress: "0xfb6ae5bcf002c1219b04d14bd2870d4707004956" })
            this.initialCheck();
        } else if (this.state.comicEdition === "3") {
            this.setState({ contractAddress: "0x2f405b55881a64ca5c3c2398fbaeaf45caa80eb1" })
            this.initialCheck();
        } else if (this.state.comicEdition === "4") {
            this.setState({ contractAddress: "0xf0a691a81bda8c07f2ed564252f1e26996d52d28" })
            this.initialCheck();
        } else {
            alert("Error with Comic ID")
        }
        
    }

    initialCheck = async () => {

        if (window.ethereum) {
            new Web3(new Web3.providers.HttpProvider("https://eth-rpc-api.thetatoken.org/rpc"))
            window.web3 = new Web3(window.ethereum);
            //const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            this.CheckNetwork();

        } else {
            window.alert('No Ethereum browser detected....check out Metamask!');
            this.setState({ loading: false })
            this.setState({ statusCode: 1 })
        }

    }

    async CheckNetwork() {
        const networkId = await window.web3.eth.net.getId();
        if (networkId !== 361) {
            this.setState({ loading: false })
            this.setState({ statusCode: 3 })
            return;
        }

        this.checkOwnership();
    }

    async checkOwnership() {
        //make call to network
        const web3 = new Web3(new Web3.providers.HttpProvider("https://eth-rpc-api.thetatoken.org/rpc"))
        window.web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const NFTContract = await new web3.eth.Contract(TNT721Contract.abi, this.state.contractAddress);

        let result = await NFTContract.methods.balanceOf(accounts[0]).call();
        if (result >= 1) {
            this.setState({ statusCode: 0 })
        } else {
            this.setState({statusCode: 4})
        }
        this.setState({ loading: false })
    }




    render() {
        return (
            <div className='home'>
                {this.state.loading &&
                    <div>
                        Loading...
                    </div>
                }
                {
                    (this.state.loading === false && this.state.statusCode === 0) &&
                    <div className='home'>
                        <ComicViewer edition={this.state.comicEdition} />
                    </div>
                }
                {
                    (!this.state.loading === false && this.state.statusCode === 1) &&
                    <div style={{ fontSize: '20pt' }}>
                        Error! Metamask not detected.
                    </div>
                }
                {
                    (this.state.loading === false && this.state.statusCode === 2) &&
                    <div style={{ fontSize: '20pt' }}>
                        Metamask not connected.
                        Please check Metamask and refresh.
                    </div>
                }
                {
                    (this.state.loading === false && this.state.statusCode === 3) &&
                    <div>
                        <h2>Not connected to the right network</h2>
                    </div>
                }
                {
                    (this.state.loading === false && this.state.statusCode === 4) &&
                    <div style={{ fontSize: '20pt' }}>
                        Sorry this account doesn't own the correct NFT.
                    </div>
                }


            </div>

        )
    }
}

export default MMConnect;
